import { default as indexGiOTGe0UluMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/basket/index.vue?macro=true";
import { default as _91slug_936glzIqGHczMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/blogs/[slug].vue?macro=true";
import { default as indexI28XXn1RMqMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/blogs/index.vue?macro=true";
import { default as _91slug_93FGoRxbsA8vMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/category/[slug].vue?macro=true";
import { default as _91slug_936Mm5DFVwauMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/game/[slug].vue?macro=true";
import { default as _91slug_93cxIotS4k56Meta } from "/home/dolphgame/public_html/dolph_front_new/pages/game/product/[slug].vue?macro=true";
import { default as indexKItMLwEuaAMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/games/index.vue?macro=true";
import { default as indexct6q2uw9rYMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/index.vue?macro=true";
import { default as aboutL2GOY9ZGxZMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/pages/about.vue?macro=true";
import { default as contactfnqhIU5OlnMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/pages/contact.vue?macro=true";
import { default as faq9At2TyS840Meta } from "/home/dolphgame/public_html/dolph_front_new/pages/pages/faq.vue?macro=true";
import { default as privacyYrV8JrjBzSMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/pages/privacy.vue?macro=true";
import { default as indexrdMwru4D7xMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/payments/index.vue?macro=true";
import { default as callbackW1SpBygHPsMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/social/callback.vue?macro=true";
import { default as accountgghvAeNgTCMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/account.vue?macro=true";
import { default as bonusesw87PQfA83IMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/bonuses.vue?macro=true";
import { default as indexrPPGcgTTdFMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/index.vue?macro=true";
import { default as notificationsiEiIMLnqQKMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/notifications.vue?macro=true";
import { default as operations7G3zBZ68xpMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/operations.vue?macro=true";
import { default as ordersGq8Ip8BglOMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/orders.vue?macro=true";
import { default as payments7j77WrhXFYMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/payments.vue?macro=true";
import { default as userkhrRKJFu1oMeta } from "/home/dolphgame/public_html/dolph_front_new/pages/user.vue?macro=true";
export default [
  {
    name: indexGiOTGe0UluMeta?.name ?? "basket",
    path: indexGiOTGe0UluMeta?.path ?? "/basket",
    meta: indexGiOTGe0UluMeta || {},
    alias: indexGiOTGe0UluMeta?.alias || [],
    redirect: indexGiOTGe0UluMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/basket/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936glzIqGHczMeta?.name ?? "blogs-slug",
    path: _91slug_936glzIqGHczMeta?.path ?? "/blogs/:slug()",
    meta: _91slug_936glzIqGHczMeta || {},
    alias: _91slug_936glzIqGHczMeta?.alias || [],
    redirect: _91slug_936glzIqGHczMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/blogs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexI28XXn1RMqMeta?.name ?? "blogs",
    path: indexI28XXn1RMqMeta?.path ?? "/blogs",
    meta: indexI28XXn1RMqMeta || {},
    alias: indexI28XXn1RMqMeta?.alias || [],
    redirect: indexI28XXn1RMqMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FGoRxbsA8vMeta?.name ?? "category-slug",
    path: _91slug_93FGoRxbsA8vMeta?.path ?? "/category/:slug()",
    meta: _91slug_93FGoRxbsA8vMeta || {},
    alias: _91slug_93FGoRxbsA8vMeta?.alias || [],
    redirect: _91slug_93FGoRxbsA8vMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Mm5DFVwauMeta?.name ?? "game-slug",
    path: _91slug_936Mm5DFVwauMeta?.path ?? "/game/:slug()",
    meta: _91slug_936Mm5DFVwauMeta || {},
    alias: _91slug_936Mm5DFVwauMeta?.alias || [],
    redirect: _91slug_936Mm5DFVwauMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/game/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cxIotS4k56Meta?.name ?? "game-product-slug",
    path: _91slug_93cxIotS4k56Meta?.path ?? "/game/product/:slug()",
    meta: _91slug_93cxIotS4k56Meta || {},
    alias: _91slug_93cxIotS4k56Meta?.alias || [],
    redirect: _91slug_93cxIotS4k56Meta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/game/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKItMLwEuaAMeta?.name ?? "games",
    path: indexKItMLwEuaAMeta?.path ?? "/games",
    meta: indexKItMLwEuaAMeta || {},
    alias: indexKItMLwEuaAMeta?.alias || [],
    redirect: indexKItMLwEuaAMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexct6q2uw9rYMeta?.name ?? "index",
    path: indexct6q2uw9rYMeta?.path ?? "/",
    meta: indexct6q2uw9rYMeta || {},
    alias: indexct6q2uw9rYMeta?.alias || [],
    redirect: indexct6q2uw9rYMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/index.vue").then(m => m.default || m)
  },
  {
    name: aboutL2GOY9ZGxZMeta?.name ?? "pages-about",
    path: aboutL2GOY9ZGxZMeta?.path ?? "/pages/about",
    meta: aboutL2GOY9ZGxZMeta || {},
    alias: aboutL2GOY9ZGxZMeta?.alias || [],
    redirect: aboutL2GOY9ZGxZMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactfnqhIU5OlnMeta?.name ?? "pages-contact",
    path: contactfnqhIU5OlnMeta?.path ?? "/pages/contact",
    meta: contactfnqhIU5OlnMeta || {},
    alias: contactfnqhIU5OlnMeta?.alias || [],
    redirect: contactfnqhIU5OlnMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faq9At2TyS840Meta?.name ?? "pages-faq",
    path: faq9At2TyS840Meta?.path ?? "/pages/faq",
    meta: faq9At2TyS840Meta || {},
    alias: faq9At2TyS840Meta?.alias || [],
    redirect: faq9At2TyS840Meta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: privacyYrV8JrjBzSMeta?.name ?? "pages-privacy",
    path: privacyYrV8JrjBzSMeta?.path ?? "/pages/privacy",
    meta: privacyYrV8JrjBzSMeta || {},
    alias: privacyYrV8JrjBzSMeta?.alias || [],
    redirect: privacyYrV8JrjBzSMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexrdMwru4D7xMeta?.name ?? "payments",
    path: indexrdMwru4D7xMeta?.path ?? "/payments",
    meta: indexrdMwru4D7xMeta || {},
    alias: indexrdMwru4D7xMeta?.alias || [],
    redirect: indexrdMwru4D7xMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: callbackW1SpBygHPsMeta?.name ?? "social-callback",
    path: callbackW1SpBygHPsMeta?.path ?? "/social/callback",
    meta: callbackW1SpBygHPsMeta || {},
    alias: callbackW1SpBygHPsMeta?.alias || [],
    redirect: callbackW1SpBygHPsMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/social/callback.vue").then(m => m.default || m)
  },
  {
    name: userkhrRKJFu1oMeta?.name ?? "user",
    path: userkhrRKJFu1oMeta?.path ?? "/user",
    meta: userkhrRKJFu1oMeta || {},
    alias: userkhrRKJFu1oMeta?.alias || [],
    redirect: userkhrRKJFu1oMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: accountgghvAeNgTCMeta?.name ?? "user-dashboard-account",
    path: accountgghvAeNgTCMeta?.path ?? ":dashboard()/account",
    meta: accountgghvAeNgTCMeta || {},
    alias: accountgghvAeNgTCMeta?.alias || [],
    redirect: accountgghvAeNgTCMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/account.vue").then(m => m.default || m)
  },
  {
    name: bonusesw87PQfA83IMeta?.name ?? "user-dashboard-bonuses",
    path: bonusesw87PQfA83IMeta?.path ?? ":dashboard()/bonuses",
    meta: bonusesw87PQfA83IMeta || {},
    alias: bonusesw87PQfA83IMeta?.alias || [],
    redirect: bonusesw87PQfA83IMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/bonuses.vue").then(m => m.default || m)
  },
  {
    name: indexrPPGcgTTdFMeta?.name ?? "user-dashboard",
    path: indexrPPGcgTTdFMeta?.path ?? ":dashboard()",
    meta: indexrPPGcgTTdFMeta || {},
    alias: indexrPPGcgTTdFMeta?.alias || [],
    redirect: indexrPPGcgTTdFMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsiEiIMLnqQKMeta?.name ?? "user-dashboard-notifications",
    path: notificationsiEiIMLnqQKMeta?.path ?? ":dashboard()/notifications",
    meta: notificationsiEiIMLnqQKMeta || {},
    alias: notificationsiEiIMLnqQKMeta?.alias || [],
    redirect: notificationsiEiIMLnqQKMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/notifications.vue").then(m => m.default || m)
  },
  {
    name: operations7G3zBZ68xpMeta?.name ?? "user-dashboard-operations",
    path: operations7G3zBZ68xpMeta?.path ?? ":dashboard()/operations",
    meta: operations7G3zBZ68xpMeta || {},
    alias: operations7G3zBZ68xpMeta?.alias || [],
    redirect: operations7G3zBZ68xpMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/operations.vue").then(m => m.default || m)
  },
  {
    name: ordersGq8Ip8BglOMeta?.name ?? "user-dashboard-orders",
    path: ordersGq8Ip8BglOMeta?.path ?? ":dashboard()/orders",
    meta: ordersGq8Ip8BglOMeta || {},
    alias: ordersGq8Ip8BglOMeta?.alias || [],
    redirect: ordersGq8Ip8BglOMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/orders.vue").then(m => m.default || m)
  },
  {
    name: payments7j77WrhXFYMeta?.name ?? "user-dashboard-payments",
    path: payments7j77WrhXFYMeta?.path ?? ":dashboard()/payments",
    meta: payments7j77WrhXFYMeta || {},
    alias: payments7j77WrhXFYMeta?.alias || [],
    redirect: payments7j77WrhXFYMeta?.redirect,
    component: () => import("/home/dolphgame/public_html/dolph_front_new/pages/user/[dashboard]/payments.vue").then(m => m.default || m)
  }
]
  }
]